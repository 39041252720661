import tradly from 'tradly';
import {
  getCookies,
  getCookie,
  setCookies,
  setCookie,
  removeCookies,
} from 'cookies-next';
import {
  get_domain_details_from_cookie,
  get_pk_key_from_cookie,
  set_host_name,
} from '../sdk_set';
import { TYPE_CONSTANT } from '../Web_constant';

export const ssr_translation_functions = async ({
  host,
  auth_key,
  currency,
  req,
  res,
  query,
  translation_group,
  translation_file,
}) => {
  const translation_result = await tradly.set_sdk
    .config({
      domain: host,
      env: process.env.ENVIRONMENT,
      pk_key: get_pk_key_from_cookie({ req: req, res: res }),
      domain_details: get_domain_details_from_cookie({ req: req, res: res }),
    })
    .then(async (res) => {
      if (res?.status === true) {
        let default_language = getCookie(
          `${set_host_name(req)}_default_language`,
          {
            req,
            res,
          }
        );

        //If default  language is undefined    than this will call from here just solve problem at first time loading
        if (!default_language) {
          default_language = await tradly.app
            .getTenantLanguages({
              authKey: auth_key ? auth_key : '',
              currency: currency,
            })
            .then((res) => {
              return res.data.languages.filter(
                (item) => item.default === true
              )[0].code;
            })
            .catch((error) => {
              console.log(' Error from SSR Translation ');
              console.log(error);
            });
        }

        const response = await tradly.app.clientTranslationsValuesByGroup({
          bodyParam: {
            language: default_language,
            group: translation_group.toString(),
          },
          authKey: auth_key ? auth_key : '',
          currency: currency,
        });
        return response.data.grouped_client_translation_values ?? {};
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log('ERROR>>>>>>>', error);
      return {};
    });

  // In ssr time set translation in file
  // translation_group foreach

  if (Object.keys(translation_result)?.length > 0) {
    translation_group.forEach((item) => {
      const item_values = translation_result[item];

      // Item values (Object) Map (by foreach)
      Object.keys(item_values).forEach(function (key) {
        const element = item_values[key];

        translation_file.forEach((file) => {
          if (
            file[item]?.[
              `${
                key.split(`${item}.`)?.length > 1
                  ? key.split(`${item}.`)[1]
                  : key.split(`${item}.`)[0]
              }`
            ] !== undefined
          ) {
            file[item][
              `${
                key.split(`${item}.`)?.length > 1
                  ? key.split(`${item}.`)[1]
                  : key.split(`${item}.`)[0]
              }`
            ] = element;
          }
        });
      });
    });
  }

  //Languages
  const tenant_languages = await tradly.set_sdk
    .config({
      domain: host,
      env: process.env.ENVIRONMENT,
      pk_key: get_pk_key_from_cookie({ req: req, res: res }),
      domain_details: get_domain_details_from_cookie({ req: req, res: res }),
    })
    .then(async (response) => {
      if (response?.status) {
        const language_response = await tradly.app.getTenantLanguages({
          authKey: auth_key ? auth_key : '',
          currency: currency,
        });

        if (
          !getCookie(`${set_host_name(req)}_default_language`, { req, res }) ||
          !getCookie(`${set_host_name(req)}_direction`, { req, res })
        ) {
          const default_language = language_response.data.languages.filter(
            (item) => item.default === true
          )[0];
          setCookie(
            `${set_host_name(req)}_default_language`,
            default_language.code,
            {
              req,
              res,
            }
          );
          setCookie(
            `${set_host_name(req)}_direction`,
            default_language?.rtl ? 'rtl' : 'ltr',
            {
              req,
              res,
            }
          );
          TYPE_CONSTANT.DIR = default_language?.rtl ? 'rtl' : 'ltr';
        } else {
          TYPE_CONSTANT.DIR = getCookie(`${set_host_name(req)}_direction`, {
            req,
            res,
          });
        }
        return language_response.data.languages ?? {};
      }
    })
    .catch((error) => {
      console.log('ERROR>>>>>>>', error?.response?.data);
      return {};
    });

  return { client_translations: translation_result };
};
