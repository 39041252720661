import tradly from 'tradly';
import { TYPE_CONSTANT } from './Web_constant';
import {
  get_domain_details_from_cookie,
  get_pk_key_from_cookie,
} from './sdk_set';

export const Common_ssr_functions = async ({
  host,
  auth_key,
  currency,
  req,
  res,
  query,
}) => {
  const configs_groups_name = `general,onboarding,accounts,listings,integrations,payments,draft_styles,seo,styles,social,address,orders,shipping,pages`;

  let configs_list = await tradly.set_sdk
    .config({
      domain: host,
      env: process.env.ENVIRONMENT,
      pk_key: get_pk_key_from_cookie({ req: req, res: res }),
      domain_details: get_domain_details_from_cookie({ req: req, res: res }),
    })
    .then(async (res) => {
      if (res?.status === true) {
        const response = await tradly.app.getGroupedConfigList({
          paramBody: configs_groups_name,
          authKey: auth_key ? auth_key : '',
          currency: currency,
        });
        return response.data.configs ?? null;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return null;
    });

  return { configs_list };
};
