/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { refreshPage } from '../store/feature/authSlice';
import {
  setGeneralConfig,
  setListingConfig,
} from '../store/feature/configsSlice';
import { home_page } from '../tradly.config';
import { TYPE_CONSTANT } from '../constant/Web_constant';
import {
  set_domain_name,
  encryptDataWithAES,
  decryptDataWithAES,
  set_host_name,
  get_pk_key_from_cookie,
  get_domain_details_from_cookie,
} from '../constant/sdk_set';
import {
  all_integrations_data,
  metadata,
  structuredData,
} from '../constant/Metadata';
import SEO_Head from '../constant/SEO_Head';
import { useRouter } from 'next/router';
import {
  set_root_configs,
  ssr_configs_set,
} from '../constant/Root_configs_set';
import { Common_ssr_functions } from '../constant/SSR_functions';
import { ssr_translation_functions } from '../constant/Translation/SSR_translation';
import { Home_page_text } from '../constant/Translation/Home';
import { Menubar_text } from '../constant/Translation/Heading';
import { General_text } from '../constant/Translation/General';
import { set_root_translation } from '../constant/Translation/translation_set';
import { Account_text } from '../constant/Translation/Account';
import { Notification_text } from '../constant/Translation/Notification';
import { Listing_text } from '../constant/Translation/Listing';
import { Layer_text } from '../constant/Translation/LayerText';
import { getCookie, setCookie } from 'cookies-next';
import tradly from 'tradly';
import { setHomeData } from '../store/feature/homeSlice';
import { setBannersData } from '../store/feature/Home/bannerSlice';
import { setBlogsData } from '../store/feature/Layers/layerSlice';
import { getReplacePageKey } from '../utils/getReplacePageKey';
import { PAGE_KEY } from '../constant/PageKeys';
import DynamicPagesLayout from '../themes/blank/DynamicPagesLayout';

const Index = ({
  configs_list_data,
  client_translations,
  home,
  banners,
  blogs,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  // Configs decryption
  const [configs_list, setConfigsList] = useState(
    JSON.parse(decryptDataWithAES(configs_list_data))
  );

  useEffect(() => {
    // Decrypt the data when the prop value changes
    const decryptedValue = JSON.parse(decryptDataWithAES(configs_list_data));
    setConfigsList(decryptedValue);
  }, [configs_list_data]);

  // refresh action call
  useEffect(() => {
    window.addEventListener('focus', () => {
      dispatch(refreshPage({}));
    });
    dispatch(refreshPage({}));
  }, []);

  useEffect(() => {
    if (configs_list) {
      dispatch(setGeneralConfig({ general_configs: configs_list?.general }));
      dispatch(setListingConfig({ listing_configs: configs_list?.listings }));
    }

    if (home) {
      dispatch(setHomeData(home));
    }

    if (banners) {
      dispatch(setBannersData(banners));
    }
    if (blogs) {
      dispatch(setBlogsData(blogs));
    }
  }, [home, configs_list, blogs, banners]);

  const pageTitle = TYPE_CONSTANT.META_TITLE;
  const pageDescription = TYPE_CONSTANT.META_DESCRIPTIONS;

  // Set Meta Data
  metadata.title = configs_list?.seo?.meta_title
    ? configs_list?.seo?.meta_title
    : '';
  metadata.meta_description = configs_list?.seo?.meta_description
    ? configs_list?.seo?.meta_description
    : '';
  all_integrations_data.google_tag_manager =
    configs_list?.integrations?.google_tag_manager;
  all_integrations_data.facebook_pixel =
    configs_list?.integrations?.facebook_pixel;
  metadata.image = configs_list?.seo?.meta_image
    ? configs_list?.seo?.meta_image
    : configs_list?.general?.web_logo;
  metadata.url = typeof window !== 'undefined' && window.location.href;
  //
  if (typeof window !== 'undefined') {
    set_root_configs({
      is_draft: router?.query?.draft,
      all_configs: configs_list,
    });

    // translation set
    set_root_translation({
      translation_values: client_translations,
      group_names: [
        'home',
        'more',
        'general',
        'storelist',
        'notification',
        'product',
        'layer',
      ],
      translation_file: [
        Home_page_text,
        Menubar_text,
        General_text,
        Account_text,
        Notification_text,
        Listing_text,
        Layer_text,
      ],
    });
  }

  //
  const isReplacePageAvailable = getReplacePageKey(
    configs_list?.pages?.replaced_dynamic_pages,
    PAGE_KEY.home
  );

  return (
    <>
      <SEO_Head />
      {isReplacePageAvailable && (
        <DynamicPagesLayout
          dynamic_page_details={{ slug: isReplacePageAvailable }}
        />
      )}
      {!isReplacePageAvailable && (
        <div>{home_page(pageTitle, pageDescription)}</div>
      )}
    </>
  );
};

export default Index;

// Server Side Function
export async function getServerSideProps({ req, res, query }) {
  var host = set_domain_name(req);
  const auth_key = req.cookies[`${set_host_name(req)}_auth_key`];
  let default_language =
    req.cookies[`${set_host_name(req)}_default_language`] ?? undefined;
  let currency =
    req.cookies[`${set_host_name(req)}_default_currency`] ?? undefined;

  // Main sdk set
  const sdk_set = await tradly.set_sdk
    .config({
      domain: host,
      env: process.env.ENVIRONMENT,
      pk_key: get_pk_key_from_cookie({ req: req, res: res }),
      domain_details: get_domain_details_from_cookie({ req: req, res: res }),
    })
    .then((res) => {
      if (res?.status !== true) {
        throw new Error('Have problme');
      } else {
        return { status: true, data: { message: 'Tenant found successfully' } };
      }
    })
    .catch((err) => {
      return err;
    });

  // tenant_languages_promise
  let tenant_languages_promise;
  if (default_language == undefined) {
    tenant_languages_promise = await tradly.app
      .getTenantLanguages({
        authKey: auth_key ? auth_key : '',
        currency: currency,
      })
      .then((lng_res) => {
        const default_language_select = lng_res.data.languages.filter(
          (item) => item.default === true
        )[0];
        default_language = default_language_select.code;
        setCookie(
          `${set_host_name(req)}_default_language`,
          default_language_select.code,
          {
            req,
            res,
          }
        );
        setCookie(
          `${set_host_name(req)}_direction`,
          default_language_select?.rtl ? 'rtl' : 'ltr',
          {
            req,
            res,
          }
        );
        TYPE_CONSTANT.DIR = default_language?.rtl ? 'rtl' : 'ltr';
        TYPE_CONSTANT.SELECTED_LANGUAGE = default_language?.code;
        return lng_res;
      })
      .catch((err) => {
        return err;
      });
  } else {
    TYPE_CONSTANT.DIR = getCookie(`${set_host_name(req)}_direction`, {
      req,
      res,
    });
    tenant_languages_promise = {};
  }

  // currency_promise
  let currency_promise;
  if (currency == undefined) {
    currency_promise = await tradly.app
      .getCurrency({
        authKey: auth_key ? auth_key : '',
        currency: currency,
        language: default_language,
      })
      .then((currency_res) => {
        const default_currency_select = currency_res.data.currencies.filter(
          (item) => item.default === true
        )[0];
        currency = default_currency_select.code;
        setCookie(
          `${set_host_name(req)}_default_currency`,
          default_currency_select.code,
          {
            req,
            res,
          }
        );
        TYPE_CONSTANT.CURRENCY = default_currency_select.code;
        return currency_res;
      })
      .catch((err) => {
        return err;
      });
  } else {
    currency_promise = {};
  }

  // all_configs_promise
  const all_configs_promise = tradly.app.getGroupedConfigList({
    paramBody: TYPE_CONSTANT.CONFIGS_GROUPS,
    authKey: auth_key ? auth_key : '',
    currency: currency,
    language: default_language,
  });

  // client_translations_promise
  const client_translations_promise =
    tradly.app.clientTranslationsValuesByGroup({
      bodyParam: {
        language: default_language,
        group: [
          'home',
          'more',
          'general',
          'storelist',
          'notification',
          'product',
          'layer',
        ].toString(),
      },
      authKey: auth_key ? auth_key : '',
      currency: currency,
    });

  //  Destructure variable
  let configs_list;
  let client_translations;
  let home;
  let banners;
  let blogs;

  try {
    // Promiss all
    let all_response = await Promise.all([
      sdk_set,
      tenant_languages_promise,
      currency_promise,
      all_configs_promise,
      client_translations_promise,
    ]);

    //  configs response
    let configs_resp = all_response[3]?.data?.configs;

    // response values destructure
    configs_list = configs_resp;
    client_translations =
      all_response[4]?.data?.grouped_client_translation_values;

    if (configs_resp?.general?.theme !== 0) {
      // Home
      const home_promise = await tradly.app.home({
        authKey: auth_key ? auth_key : '',
        currency: currency,
        language: default_language,
      });

      // Promo Banner
      const banners_promise = await tradly.app.getPromoBanner({
        authKey: auth_key ? auth_key : '',
        currency: currency,
        language: default_language,
        bodyParam: {},
      });

      // Blogs
      const blogs_promise = await tradly.app.getLayer({
        authKey: auth_key ? auth_key : '',
        currency: currency,
        language: default_language,
        bodyParam: { page: 1, type: 'blog' },
      });

      // Promiss all
      let old_home_response = await Promise.all([
        home_promise,
        banners_promise,
        blogs_promise,
      ]);

      // old_home_response
      home = old_home_response[0]?.data ?? {};
      banners = old_home_response[1]?.data?.promo_banners ?? {};
      blogs = old_home_response[2]?.data ?? {};
    }

    //ssr_configs_set
    ssr_configs_set({
      is_draft: query.draft,
      all_configs: all_response[1]?.data?.configs,
    });

    // translation set
    set_root_translation({
      translation_values: client_translations,
      group_names: [
        'home',
        'more',
        'general',
        'storelist',
        'notification',
        'product',
        'layer',
      ],
      translation_file: [
        Home_page_text,
        Menubar_text,
        General_text,
        Account_text,
        Notification_text,
        Listing_text,
        Layer_text,
      ],
    });

    // metadata
    metadata.title = configs_list?.seo?.meta_title;
    metadata.meta_description = configs_list?.seo?.meta_description;
    metadata.url = req?.headers?.host + req?.url;
    metadata.image = configs_list?.seo?.meta_image
      ? configs_list?.seo?.meta_image
      : configs_list?.general?.web_logo;
    all_integrations_data.google_tag_manager =
      configs_list?.integrations?.google_tag_manager;
    all_integrations_data.facebook_pixel =
      configs_list?.integrations?.facebook_pixel;
    structuredData.json_data = {};
  } catch (error) {
    configs_list = {};
    client_translations = {};
    home = {};
    banners = {};
    blogs = {};
  }

  // configs set
  ssr_configs_set({ is_draft: query.draft, all_configs: configs_list });

  return {
    props: {
      configs_list_data: encryptDataWithAES(JSON.stringify(configs_list)),
      client_translations,
      home: home ?? {},
      banners: banners ?? {},
      blogs: blogs ?? {},
    },
  };
}
