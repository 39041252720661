import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useRouter } from 'next/router';
import {
  clearErrorState,
  homePageSelector,
} from '../../store/feature/Pages/Home/homePageSlice';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import {
  HomeBoxDefaultStyles,
  HomeDefaultColumnStyles,
  HomeMainDefaultStyles,
} from '../../constant/default_styles/HomeDefaultStyles';
import { useScreenSize } from '../../hooks/useScreenSize';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { dynamic_page_component_decide } from './BlockDecide/DynamicPageBlockDecide';
import DynamicHeader from '../../components/Header/DynamicHeader';
import DynamicFooter from '../../components/Footer/DynamicFooter';
import {
  editor_versions_list,
  latest_editor_version,
} from '../../constant/Version';
import GlobalSidebar from '../../components/Shared/Modal/GlobalSidebar';
import {
  check_authorized_people,
  check_unauthorized_guest,
  verify_store_account,
} from '../../utils/CheckAuthorizationCondition';
import {
  dynamicPageSelector,
  getDynamicPageSections,
} from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import DynamicBottomTabbar from '../../components/BottomTab/DynamicBottomTabbar';

const DynamicPagesLayout = ({ dynamic_page_details }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { width, height } = useScreenSize();

  //
  const { isError, errors, dynamic_page_sections } =
    useSelector(dynamicPageSelector);

  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  // close pop up
  const closePopUP = () => {
    dispatch(clearErrorState());
    setShowError(false);
    setError_message('');
  };

  //select blocks
  const [BlocksList, setBlocksList] = useState(
    TYPE_CONSTANT.PAGES?.[dynamic_page_details?.slug]
  );
  const DynamicBottombarStyle =
    TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_bottom_tabbar;

  // conditions
  let logged_in_condition =
    BlocksList?.styles?.settings?.show_for_logged_in_user;
  let guest_user_condition = BlocksList?.styles?.settings?.show_for_guest_user;
  let verify_store_condition =
    BlocksList?.styles?.settings?.verify_with_store_account;

  //

  const [AllListingFilter, setAllListingsFilter] = useState({});
  const [AllAccountFilter, setAllAccountFilter] = useState({});

  // data fetching
  useEffect(() => {
    if (BlocksList?.schema?.length > 0) {
      let all_items = BlocksList?.schema.map(
        ({ row_styles, columns, ...column_styles }) => {
          const all_columns_items = columns?.map((column) => column);
          const flattenedArray = all_columns_items.flat();
          // Remove empty inner arrays from the flattened array.
          const filteredArray = flattenedArray.filter(
            (element) => element.length !== 0
          );
          return filteredArray;
        }
      );
      const flattenedArray = all_items.flat();

      // Remove empty inner arrays from the flattened array.
      const filteredArray = flattenedArray.filter(
        (element) => element.length !== 0
      );

      dispatch(getDynamicPageSections({ sections_data: filteredArray }));
    }
  }, [BlocksList]);

  // if single condition doesnt match
  if (
    !check_unauthorized_guest(guest_user_condition) ||
    !check_authorized_people(logged_in_condition) ||
    !verify_store_account(verify_store_condition)
  ) {
    router.push('/');

    return false;
  }

  return (
    check_unauthorized_guest(guest_user_condition) &&
    check_authorized_people(logged_in_condition) &&
    verify_store_account(verify_store_condition) && (
      <>
        {/* Error handler */}
        {(isError || showError) && (
          <OutsideClickHandler
            onOutsideClick={() => {
              (isError || showError) &&
                (setShowError(false),
                setError_message(''),
                dispatch(clearErrorState()));
            }}
          >
            <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
              <div className="flex flex-col gap-2">
                {errors?.map((err) => {
                  return <PopUp message={err?.msg} closePopUP={closePopUP} />;
                })}
              </div>
            </div>
          </OutsideClickHandler>
        )}
        {BlocksList?.styles?.settings?.show_main_header && <DynamicHeader />}

        {/* New blocks */}
        {/* Rows */}

        <div
          style={get_dynamic_styles_with_default_value(
            responsive_design_styles({
              width,
              parent_group: BlocksList?.styles,
              child_group_name: 'main_styles',
            }),
            HomeMainDefaultStyles
          )}
        >
          {' '}
          <div
            className="flex flex-col  "
            style={get_dynamic_styles_with_default_value(
              responsive_design_styles({
                width,
                parent_group: BlocksList?.styles,
                child_group_name: 'box_styles',
              }),
              HomeBoxDefaultStyles
            )}
          >
            {BlocksList?.schema?.map((row) => {
              const {
                columns,
                current_editor_version = '',
                ...column_styles
              } = row || {};
              const row_styles = responsive_row_styles({
                parent_group: row,
                width,
              });
              return (
                <div
                  className={``}
                  style={
                    current_editor_version == latest_editor_version
                      ? {
                          display: row_styles?.display,
                          gridTemplateColumns:
                            row_styles?.columns_box_percentage,
                          ...get_dynamic_styles(row_styles),
                        }
                      : {
                          ...get_dynamic_styles(row_styles),
                          display:
                            row_styles?.isShowInMobile === false
                              ? 'none'
                              : 'grid',
                          gap: row_styles?.gap_between_columns ?? '24px',
                          gridTemplateColumns:
                            width > 768
                              ? row_styles?.columns_box_percentage
                                ? row_styles?.columns_box_percentage
                                : `repeat(${
                                    row.columns?.length ?? 1
                                  }, minmax(0, 1fr))`
                              : row?.['768_row_styles']
                              ? row_styles?.columns_box_percentage
                              : '100%',
                        }
                  }
                >
                  {/* Columns */}
                  {columns?.map((column, index) => {
                    const current_column_style = responsive_column_styles({
                      parent_group: column_styles,
                      index,
                      width,
                    });

                    return (
                      <div
                        className="flex   "
                        style={{
                          ...get_dynamic_styles_with_default_value(
                            current_column_style,
                            HomeDefaultColumnStyles
                          ),
                        }}
                      >
                        {column?.map((item) => {
                          return (
                            dynamic_page_component_decide({
                              ...item,
                              dynamic_page_sections,
                              dynamic_page_sections,
                              AllListingFilter,
                              setAllListingsFilter,
                              screen_width: width,
                            }) &&
                            (editor_versions_list?.includes(
                              current_editor_version
                            ) ? (
                              <>
                                {dynamic_page_component_decide({
                                  ...item,
                                  dynamic_page_sections,
                                  dynamic_page_sections,
                                  AllListingFilter,
                                  setAllListingsFilter,
                                  screen_width: width,
                                  AllAccountFilter,
                                  setAllAccountFilter,
                                })}
                              </>
                            ) : (
                              <div>
                                {dynamic_page_component_decide({
                                  ...item,
                                  dynamic_page_sections,
                                  dynamic_page_sections,
                                  AllListingFilter,
                                  setAllListingsFilter,
                                  screen_width: width,
                                  AllAccountFilter,
                                  setAllAccountFilter,
                                })}
                              </div>
                            ))
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        {BlocksList?.styles?.settings?.show_main_footer && <DynamicFooter />}
        {BlocksList?.styles?.settings?.show_main_header &&
          DynamicBottombarStyle?.schema?.length > 0 && <DynamicBottomTabbar />}

        <GlobalSidebar />
      </>
    )
  );
};

export default DynamicPagesLayout;
