import { getCookie } from 'cookies-next';
import { TYPE_CONSTANT } from '../constant/Web_constant';

// check_unauthorized_guest
export const check_unauthorized_guest = (isShowUnauthorizedGuest) => {
  const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);
  return isShowUnauthorizedGuest ? (isLoggedIN ? false : true) : true;
};

// check_authorized_peop
export const check_authorized_people = (isShowAuthorizedPeople) => {
  const isLoggedIN = getCookie(`${TYPE_CONSTANT.DOMAIN}_login`);
  return isShowAuthorizedPeople ? (isLoggedIN ? true : false) : true;
};

// check_authorized_peop
export const verify_store_account = (isVerifyWithStore) => {
  const isHaveStore = getCookie(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`);
  return isVerifyWithStore ? (isHaveStore ? true : false) : true;
};
